/* eslint-disable */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import axiosInstance from "@/services/axios";
import VueSweetalert2 from "vue-sweetalert2";
import firebaseMessaging from "./firebase";
import i18n from "./i18n"; 
import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "./assets/styles/style.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vuetify/styles";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
});

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App).use(i18n);
app.component('VueDatePicker', VueDatePicker);

app.use(vuetify);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCyPvYyzZWltvd6J2jcdjs4Xo3DQu7QAmM",
    libraries: "places",
  },
});
app.config.globalProperties.$messaging = firebaseMessaging;
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.config.globalProperties.$axios = { ...axiosInstance() };
app.mount("#app");
