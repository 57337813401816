import axios from "@/services/axios";

export default {
  namespaced: true,
  state: {
    details: [],
    categories: [],
    btnLastLoading: false,
    vendor: false,
    isEditing: false,
  },
  mutations: {
    SET_DATA(state, data) {
      state.details = data;
    },
    SET_V_MODEL(state, data) {
      state.vendor = data;
    },
    SET_CAT(state, data) {
      state.categories = data;
    },
    SET_LOADING(state, data) {
      state.btnLastLoading = data;
    },
    SET_ISEDITING(state, data) {
      state.isEditing = data;
    },
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    async getCategories({ commit }) {
      try {
        var res = await axios().get("services/categories");
        commit("SET_CAT", res.data);
        // console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getDetail({ commit }, payload) {
      try {
        console.log(payload);
        const dataDetail = await axios().get(`/services/details/${payload.id}`);
        // services
        console.log(dataDetail)
        const excludedCate = ["services"];
        if (excludedCate.includes(payload.cate)) {
          commit("SET_DATA", dataDetail.data);
        } else {
          commit("SET_DATA", dataDetail.data[0]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearData({ commit }) {
      commit("SET_DATA", []);
    },
    setEditing({ commit }, payload) {
      commit("SET_ISEDITING", payload);
    },
  },
};
